import { CanActivateChildFn, CanActivateFn, Router, UrlTree } from "@angular/router";
import { AuthService } from "../auth.service";
import { of, switchMap } from "rxjs";
import { inject } from "@angular/core";

export const PermisoGuard: CanActivateFn | CanActivateChildFn | UrlTree = (route, state) => {
    const router: Router = inject(Router);

    // Check the authentication status
    return inject(AuthService)
        .verificarRutasPermitidas(route.routeConfig.path)
        .pipe(
            switchMap((routeAllow) => {
                // Sí el usuario logueado no tiene la ruta permitida, redirige a vista de acceso denegado.
                if (!routeAllow) {
                    const urlTree = router.createUrlTree(['/acceso-denegado'])
                    return of(urlTree);
                } else {
                    // Allow the access
                    return of(true);
                }
            }),
        );
};