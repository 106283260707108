
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class HeaderTextService {
  private titulo: String;

  /**
   * Getter for titulo
   */
  getTitulo(): String {
    return this.titulo;
  }

  setTitulo(route: Router) {
    this.titulo = route.url;
  }
}
