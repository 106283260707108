<!-- Bar search -->
<ng-container>
    <div class="w-full p-3">
        <span class="text-gray-700 font-bold text-md lg:text-2xl dark:text-white p-1">{{
            getTitulo()
            }}</span>
        <!-- <span class="text-sm font-semibold tracking-wider text-secondary">{{
            getPath()
            }}</span> -->
    </div>
</ng-container>