
export const TitleType = [
  // Inicio
  { icon: "", text: "Inicio", path: "/inicio" },
  // Complejo
  { icon:"", text: "Naciones Unidas", path:"/complejo/1" },
  { icon:"", text: "Velez Sarfield", path:"/complejo/2" },
  { icon:"", text: "Linares", path:"/complejo/3" },
  { icon:"", text: "O'Higgins", path:"/complejo/4" },
  { icon:"", text: "Lamarque N° 1032", path:"/complejo/5" },
  { icon:"", text: "Lamarque N° 1062", path:"/complejo/6" },
  { icon:"", text: "Teniente Ibañez", path:"/complejo/7" },
  { icon:"", text: "Chacabuco", path:"/complejo/8" },
  { icon:"", text: "Edificio B66 - Juan", path:"/complejo/11" },
  { icon:"", text: "Alberdi", path:"/complejo/13" },
  { icon:"", text: "Edificio L1", path:"/complejo/14" },
  { icon:"", text: "Edificio B66", path:"/complejo/15" },
  { icon:"", text: "Casa JC", path:"/complejo/16" },
  { icon:"", text: "Casa HH", path:"/complejo/17" },
  { icon:"", text: "Oro Urbano", path:"/complejo/18" },
  { icon:"", text: "Prada Victoria", path:"/complejo/20" },
  { icon:"", text: "Terceros", path:"/complejo/21" },
  // Contratos
  { icon: "", text: "Contratos", path: "/contratos" },
  // Indices
  { icon: "", text: "Valor de Índices Histórico", path: "/indices" },
  // Expensas
  { icon: "", text: "Expensas", path: "/expensas" },
  // Filtros
  { icon: "", text: "Disponibles", path: "/disponibles" },
  { icon: "", text: "Próximo a Vencer", path: "/proximo-vencer" },
  // Reservas
  { icon: "", text: "Reservas", path: "/reservas" },
  { icon: "", text: "Pedido de Reserva", path: "/reservas/pedido-reserva" },
  // Deudas
  { icon: "", text: "Deudas Por Inquilino", path: "/por-inquilino" },
  { icon: "", text: "Deudas Por Período", path: "/por-periodo" },
  { icon: "", text: "Deudas Por Complejo", path: "/por-complejo" },
  { icon: "", text: "Detalle de Deuda", path: "/detalles-deuda" },
  { icon: "", text: "Incobrables", path: "/incobrables" },
  // Dashboard
  { icon: "", text: "Dashboard", path: "/dashboard" },
  { icon: "", text: "Nueva Captación", path: "/dashboard/nueva-captacion" },
  { icon: "", text: "Nueva Demanda", path: "/dashboard/nueva-demanda" },
];