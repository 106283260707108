import { ComponentType } from "@angular/cdk/portal";
import { ElementRef, Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { Router } from "@angular/router";
import { Subject } from "rxjs/internal/Subject";

@Injectable({
    providedIn: "root",
})
export class SearchService {
    private searchText = new Subject<string>();
    private search: FormControl = new FormControl();
    private filterEvent = new Subject();
    private filterOrigin: MatButton;

    /**
     * Getter for searchText
     */
    getSearchText(): Subject<string> {
        return this.searchText;
    }

    /**
     * Setter for searchText
     * @param textoBuscar Son los valores que va tomando el input de la barra de busqueda.
     */
    setSearchText(textoBuscar: any): void {
        // setSearchText(textoBuscar: Subject<string>): void {
        const txt = new Subject<any>()
        txt.next(textoBuscar)
        this.searchText = txt;
    }

    getSearchInput(): FormControl {
        return this.search;
    }

    /**
     * Esta función sirve para setear los valores que se ingresen en la navegador de busqueda
     * @param searchInput 
     */
    setSearchInput(searchInput: FormControl): void {
        this.search = searchInput;
    }

    unsubscribe(): void {
        this.searchText.unsubscribe();
        this.searchText = new Subject<string>();
        this.search.setValue('');
        this.search.enable();
        this.filterEvent.unsubscribe();
        this.filterEvent = new Subject();
    }

    setFilterEvent(filterEvent: Subject<any>): void {
        this.filterEvent = filterEvent;
    }

    getFilterEvent(): Subject<any> {
        return this.filterEvent;
    }

    getFilterOrigin(): MatButton {
        return this.filterOrigin;
    }

    setFilterOrigin(filterOrigin: MatButton): void {
        this.filterOrigin = filterOrigin;
    }
    /*   setFilterComponent(filterComponent: ComponentType<any>): void {
        this.filterComponent = filterComponent;
      }
    
      getFilterComponent(): ComponentType<any> {
        return this.filterComponent;
      }
    
      setFilterData(filterData: any): void {
        this.filterData = filterData;
      }
    
      getFilterData(): any {
        return this.filterData;
      } */
}