import { Component, ComponentFactoryResolver, OnInit, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { CustomSnackbarComponent } from '@shared/components/custom-snackbar/custom.component';
import { filter } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
    private forceReload = false;


    constructor(
        private swUpdate: SwUpdate,
        private resolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef,
        private router: Router,
    ) {
        if(this.swUpdate.isEnabled){
            this.router.events.pipe(
              filter(event => event instanceof NavigationEnd)
            ).subscribe(() => {
                setTimeout(() => {
                    /*Por si alguien reporta problemas de caché (suele pasar de que se vuelve a versiones viejas del service-worker).
                    * Se cambia a true el valor inicial de forceReload
                    */
                    if(this.forceReload){
                        window.location.reload();
                        this.forceReload = false;
                    }
                    this.swUpdate.checkForUpdate().then(() => {
                        this.swUpdate.versionUpdates.subscribe(event => {
                            if (event.type === 'VERSION_READY') {
                                this.swUpdate.activateUpdate().then(() => {
                                    localStorage.setItem('app-updated', 'true');
                                    window.location.reload();
                                });
                            }
                        });
                    }).catch(err => {
                        console.error('Error al verificar actualizaciones:', err);
                    });
                }, 2000);
            });
        }
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(){
        if (localStorage.getItem('app-updated')) {
            if(window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone){
                this.showCustomSnackbar('La APP ha sido actualizada');
            }
            setTimeout(() => {
                this.viewContainerRef.clear();
            }, 5000);
            localStorage.removeItem('app-updated');
        }
    }

    showCustomSnackbar(message: string): void {
        this.viewContainerRef.clear();
        const factory = this.resolver.resolveComponentFactory(CustomSnackbarComponent);
        const componentRef = this.viewContainerRef.createComponent(factory);
        componentRef.instance.message = message;
    }
}