import { Route } from '@angular/router';
import { initialDataResolver } from './app.resolvers';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { NoAuthGuard } from './core/auth/guards/noAuth.guard';
import { LayoutComponent } from './layout/layout.component';
import { PermisoGuard } from '@core/auth/guards/permiso.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/inicio'
    { 
        path: '', 
        pathMatch: 'full', 
        redirectTo: 'inicio' 
    },

    // Redirect 'denegado' path to '/acceso-denegado'
    { path: 'acceso-denegado', pathMatch: 'full', redirectTo: 'acceso-denegado' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'inicio' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('./modules/auth/confirmation-required/confirmation-required.routes') },
            { path: 'forgot-password', loadChildren: () => import('./modules/auth/forgot-password/forgot-password.routes') },
            { path: 'reset-password', loadChildren: () => import('./modules/auth/reset-password/reset-password.routes') },
            { path: 'sign-in', loadChildren: () => import('./modules/auth/sign-in/sign-in.routes') },
            { path: 'sign-up', loadChildren: () => import('./modules/auth/sign-up/sign-up.routes') }
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('./modules/auth/sign-out/sign-out.routes') },
            { path: 'acceso-denegado', loadChildren: () => import('./modules/admin/acceso-denegado/acceso-denegado.routes') },
            // Home
            {
                path: 'inicio',
                loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
            },
            {
                path: 'complejo/:id',
                loadChildren: () => import('./modules/unidad-funcionales/unidad-funcional.module').then(m => m.UnidadFuncionalModule)
            },
            // Administracion
            {
                path: 'contratos',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/administracion/contratos/contratos.module').then(m => m.ContratosModule)
            },
            {
                path: 'indices',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/administracion/indices/indices.module').then(m => m.IndicesModule)
            },
            {
                path: 'expensas',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/administracion/expensas/expensas.module').then(m => m.ExpensasModule)
            },
            // Deudas
            {
                path: 'por-inquilino',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/deudas/por-inquilino/por-inquilino.module').then(m => m.PorInquilinoModule)
            },
            {
                path: 'detalles-deuda/:id',
                loadChildren: () => import('./modules/deudas/detalle-deuda/detalle-deuda.module').then(m => m.DetalleDeudaModule)
            },
            {
                path: 'por-periodo',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/deudas/por-periodo/por-periodo.module').then(m => m.PorPeriodoModule)
            },
            {
                path: 'por-complejo/:periodomes/:periodoanio',
                loadChildren: () => import('./modules/deudas/por-complejo/por-complejo.module').then(m => m.PorComplejoModule)
            },
            {
                path: 'por-complejo',
                loadChildren: () => import('./modules/deudas/por-complejo/por-complejo.module').then(m => m.PorComplejoModule)
            },
            {
                path: 'incobrables',
                loadChildren: () => import('./modules/deudas/incobrables/incobrables.module').then(m => m.IncobrablesModule)
            },
            // Filtros
            {
                path: 'disponibles',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/filtros/disponibles/disponibles.module').then(m => m.DisponiblesModule)
            },
            {
                path: 'proximo-vencer',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/filtros/proximos-vencer/proximo-vencer.module').then(m => m.ProximosVencerModule)
            },
        ]
    }
];