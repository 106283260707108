import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { AuthUtils } from '@core/auth/auth.utils';

@Injectable({
    providedIn: 'root'
})
export class TokenService {
    private checkInterval = 10000; // 10 segundos en milisegundos
    private alertBeforeExpire = 5 * 60 * 1000; // 5 minutos en milisegundos antes de la expiración
    private tokenCheckIntervalId: any;
    token: string;
    avisado: boolean = false;
    currentUrl: string;

    constructor(private router: Router, private authService: AuthService) {
        this.startTokenCheck();
    }

    startTokenCheck() {
        this.tokenCheckIntervalId = setInterval(() => {
            this.logCurrentUrl();
            if (this.currentUrl != '/sign-in') {
                this.checkToken();
            }
        }, this.checkInterval);
    }

    private logCurrentUrl() {
        this.currentUrl = this.router.url;
    }

    private checkToken() {
        const token = this.authService.accessToken;
        if (!token) return;

        const expirationTime = AuthUtils._getTokenExpirationDate(token).getTime();
        const currentTime = Date.now();
        const timeUntilExpiration = expirationTime - currentTime;

        if (timeUntilExpiration <= this.alertBeforeExpire && timeUntilExpiration > 0 && !this.avisado) {
            this.avisado = true;
            const extend = confirm('Tu sesión está a punto de expirar. ¿Deseas extenderla?');
            if (extend) {
                clearInterval(this.tokenCheckIntervalId);
                this.renewToken();
            } else {
                this.authService.signOut();
                this.router.navigate(['/sign-out']);
            }
        }
    }

    private renewToken() {
        this.authService.renewToken().subscribe({
            next: (res: any) => {
                this.authService.accessToken = res.token;
                alert('Tu sesión ha sido extendida.');
                this.avisado = false; // Restablecer avisado para futuras verificaciones
                this.startTokenCheck();
            },
            error: (error) => {
                this.authService.signOut();
            }
        });
    }
}