const $BASE_API = "https://bauenbackend.jeds.ar";
const $BASE_REST = "https://bauenbackend.jeds.ar/web";
// const $BASE_REST = "http://localhost/bauen_backend/web";
// const $BASE_REST = "http://192.168.0.174/bauen_backend/web";

export const environment = {
      production: false,
      baseRest: $BASE_REST,
      login: {
            login: `${$BASE_REST}/usuario/login`,
            view_usuarios: `${$BASE_REST}/view_usuarios`,
            usuarios_login: `${$BASE_REST}/usuario/imagenes`
      },
      inicio: {
            view_complejos: `${$BASE_REST}/view_complejos?filter[cantidad_uf][gte]=1`,
            logo_complejos: `${$BASE_REST}`, // uploads/nombre_logo se lo concatena en home.component.ts
            view_unidad_funcionals: `${$BASE_REST}/view_unidad_funcionals?filter[complejo_id]=`
      },
      administracion: {
            contratos: {
                  contrato: `${$BASE_REST}/ctr_contrato`,
                  indice: `${$BASE_REST}/ctr_contrato/set_indices`,
                  alquiler: `${$BASE_REST}/ctr_contrato/actualizar_cuotas`,
                  adjunto: `${$BASE_REST}/ctr_contrato/cargar_adjunto`,
                  ver_adjunto: `${$BASE_REST}`, // uploads/nombre_pdf se lo concatena en adjunto.component.ts
                  aumento: `${$BASE_REST}/ctr_contrato/aumento`,
                  confirmar_expensa: `${$BASE_REST}/ctr_contrato/actualizar_expensas`,
                  ajuste_periodo: `${$BASE_REST}/ctr_contrato/actualizar_cuotas_actual`,
                  contrato_detalle: `${$BASE_REST}/ctr_contrato/detalle`,
                  renueva_contrato: `${$BASE_REST}/ctr_contratos`,
                  cancelacion_anticipada: `${$BASE_REST}/ctr_contratos`
            },
            view_contrato_activos: `${$BASE_REST}/view_contrato_activos`,
            indices: {
                  indice: `${$BASE_REST}/ctr_indice_valors`,
            },
            view_indice_valor: `${$BASE_REST}/view_indice_valors`,
            expensas: {
                  expensa: `${$BASE_REST}/ctr_expensas`
            },
            view_expensas: `${$BASE_REST}/view_expensas`,
            view_expensa_comprobantes: `${$BASE_REST}/view_expensa_comprobantes`,
      },
      deudas: {
            por_inquilino: {
                  view_deudor_inquilinos: `${$BASE_REST}/view_deudor_inquilinos`,
                  view_deudor_detalle: `${$BASE_REST}/view_deudor_detalle?filter[idcliente]=`,
                  marcar_incobrable: `${$BASE_REST}/clientes`
            },
            por_periodo: {
                  view_deudor_periodos: `${$BASE_REST}/view_deudor_periodos`,
                  view_deudor_complejo: `${$BASE_REST}/view_deudor_complejo`
            },
            incobrables: {
                  view_incobrables: `${$BASE_REST}/view_deudor_inquilinos?filter[incobrable]=1`
            }
      },
      filtros: {
            disponibles: {
                  view_disponibles: `${$BASE_REST}/view_unidad_funcional_estado`
            },
            proximos_a_vencer: {
                  view_proximos_a_vencer: `${$BASE_REST}/view_contrato_activos?filter[proximo_vencer]=1&filter[corresponde_actualizar]=0`
            }
      },
      select: {
            indices: `${$BASE_REST}/selects?modelo=Indice&campo_activo=activo&campo_id=idindice&campo_descripcion=descripcion`,
            complejo: `${$BASE_REST}/select?modelo=CtrComplejo&campo_id=codigo&campo_descripcion=descripcion&campo_activo=`,
            cliente: `${$BASE_REST}/select?modelo=Cliente&campo_id=codigo&campo_descripcion=razonSocial&campo_activo=activo`
      },
      menu: `${$BASE_REST}/com_menus`
};
