<div class="fixed inset-0 sm:static max-h-screen sm:inset-auto flex flex-col sm:min-w-auto sm:w-auto sm:rounded-2xl overflow-y-auto overflow-hidden shadow-lg bg-white dark:bg-slate-900">
    <!-- Content -->
    <div class="w-full p-10 pb-0 flex justify-between">
        <ng-container *ngIf="data.title || data.message">
            <!-- Title -->
            <ng-container *ngIf="data.title">
                <div
                    class="w-full text-black font-semibold text-3xl"
                    [innerHTML]="data.title"></div>
            </ng-container>

            <div class="absolute top-0 right-0 p-3">
                <button mat-icon-button (click)="onCloseMenu()">
                    <mat-icon class="text-slate-400 text-2xl">close</mat-icon>
                </button>
            </div>
        </ng-container>
    </div>

    <div class="w-full h-full p-10 pt-6">
        <!-- Message -->
        <ng-container *ngIf="data.message">
            <div
                class="text-secondary"
                [innerHTML]="data.message">
            </div>
        </ng-container>
    </div>

    <!-- Actions -->
    <div class="w-full md:flex md:justify-center md:pb-6 md:px-6 mt-auto">
        <ng-container *ngIf="data.actions.confirm.show || data.actions.cancel.show">
            <!-- Cancel -->
            <ng-container *ngIf="data.actions.cancel.show">
                <button
                    mat-stroked-button
                    [matDialogClose]="'cancelled'">
                    {{data.actions.cancel.label}}
                </button>
            </ng-container>

            <!-- Confirm -->
            <ng-container *ngIf="data.actions.confirm.show">
                <button
                    mat-flat-button
                    [color]="data.actions.confirm.color"
                    [matDialogClose]="'confirmed'" class='w-full bg-primary rounded-none md:w-28 md:rounded-lg py-8 md:py-0'>
                    {{data.actions.confirm.label}}
                </button>
            </ng-container>
        </ng-container>
    </div>
</div>
